<template>
    <div v-if="submission">
        <b-card title="Osebni zdravnik">
            <div>
                <div class="pb-1">
                    <b-badge variant="warning" v-if="submission.doctor_id"> Izbrano s seznama šifrantov</b-badge>
                    <b-badge variant="warning" v-else> Ročni vnos</b-badge>
                </div>
                <b-row class="ml-auto">
                    <div>
                        <h5>Ime in priimek zdravnika</h5>
                        <p v-if="submission.doctor_id">{{submission.doctor.first_name}} {{submission.doctor.last_name}}</p>
                        <p v-else>{{submission.doctor_first_name}} {{submission.doctor_last_name}}</p>
                    </div>
                    <div class="ml-sm-1" >
                        <h5>Naziv zdravstvene ustanove/ambulante</h5>
                        <p v-if="submission.doctor_id">{{submission.doctor.title_1}} {{submission.doctor.title_2}}</p>
                        <p v-else>{{submission.doctor_institution}}</p>
                    </div>
                </b-row>

                <b-form-checkbox v-model="edit">Uredi</b-form-checkbox>
                <b-row class="w-100 mt-1" v-if="edit">
                    <b-col cols="12" md="6">
                        <div class="d-inline-flex w-100">
                            <vue-select  :options="doctors" v-model="newDoctor" label="first_name" class="w-100" :reduce="x => x.id"
                                         :clearable="false" :filterable="true" :searchable="true"
                            >
                                <template #option="{first_name,last_name,title_1,title_2}">
                                    <span>{{`${last_name} ${first_name} ${title_1} ${title_2}`}}</span>
                                </template>
                                <template #selected-option="{first_name,last_name,title_1,title_2}">
                                    <span>{{`${last_name} ${first_name} ${title_1} ${title_2}`}}</span>
                                </template>
                            </vue-select>
                            <b-button :variant="newDoctor ? 'primary' : 'outline-primary'" @click="updateDoctor" :disabled="!newDoctor"><feather-icon icon="FileIcon"/></b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card>
            <div>
                <div>
                    <h3>PRIJAVA NA LETOVANJE</h3>
                    <div class="d-sm-flex">
                        <div>
                            <h5>Izmena</h5>
                            <p>{{$dayjs(submission.term.date_from).format('DD.MM.YYYY')}} - {{$dayjs(submission.term.date_to).format('DD.MM.YYYY')}}</p>
                        </div>
                        <div class="ml-sm-3">
                            <h5>Način letovanja</h5>
                            <p>{{submissionType(submission.submission_type)}}</p>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <h3>PODATKI O OTROKU</h3>
                    <div class="d-md-flex justify-content-md-between">
                        <div>
                            <h5>Ime in priimek otroka</h5>
                            <p>{{submission.first_name}} {{submission.last_name}}</p>
                        </div>
                        <div>
                            <h5>EMŠO</h5>
                            <p>{{submission.emso}}</p>
                        </div>
                        <div v-if="submission.submission_type === 2 || submission.submission_type === 4">
                            <h5>ZZZS Številka</h5>
                            <p>{{submission.zzzs_number}}</p>
                        </div>
                        <div>
                            <h5>Spol</h5>
                            <p>{{gender(submission.gender)}}</p>
                        </div>
                    </div>
                    <div class="mt-1 d-md-flex justify-content-md-between">
                        <div>
                            <h5>Kraj rojstva</h5>
                            <p>{{submission.birth_place}}</p>
                        </div>
                        <div>
                            <h5>Številka osebnega dokumenta</h5>
                            <p>{{submission.document_id}}</p>
                        </div>

                        <div>
                            <h5>Datum in kraj izdaje</h5>
                            <p>{{$dayjs(submission.document_date_issued).format('DD.MM.YYYY')}}, {{submission.document_place_issued}}</p>
                        </div>
                        <div>
                            <h5>Tip osebnega dokumenta</h5>
                            <p>{{submission.document_type === 1 ? 'Osebna' : 'Potni list'}}</p>
                        </div>
                    </div>
                    <div class="mt-1 d-sm-flex">
                        <div>

                            <h5>Stalno prebivališče</h5>
                            <p>{{formatAddress(submission.address)}}</p>
                        </div>
                        <div class="ml-sm-3">
                            <h5>Občina stalnega prebivališča</h5>
                            <p>{{submission.municipality.name}}</p>
                        </div>
                    </div>
                    <div class="mt-1 d-md-flex justify-content-md-between">
                        <div>
                            <h5>Državljanstvo</h5>
                            <p>{{submission.citizenship}}</p>
                        </div>
                        <div>
                            <h5>Tip šole</h5>
                            <p>{{schoolType(submission.school_type)}}</p>
                        </div>
                        <div>
                            <h5>Šola/Vrtec</h5>
                            <p>{{submission.school}}</p>
                        </div>
                        <div>
                            <h5>Razred</h5>
                            <p>{{submission.class}}</p>
                        </div>
                    </div>
                    <div class="mt-1">
                        <div>
                            <h5>Želje otroka</h5>
                            <p>{{submission.classification_wish}}</p>
                        </div>
                    </div>

                    <div class="mt-1" v-if="submission.child_allowance_document_name">
                        <div>
                            <h5>Odločba o otroškem dodatki</h5>
                            <b-button @click="downloadPdf(submission.child_allowance_document_name)" variant="primary" size="sm">Prenesi dokumente</b-button>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <h3>PODATKI O STARŠIH OZIROMA SKRBNIKIH</h3>
                    <div class="d-md-flex justify-content-md-between">
                        <div>
                            <h4>{{getParentTypeText(submission.mother_data.parent_type)}}</h4>
                            <div>
                                <h5>Status</h5>
                                <p> {{submission.mother_data.parent_status === 1 ? 'Zaposlen' : submission.mother_data.parent_status === 2 ? 'Nezaposlen' : 'Drugo'}}</p>
                            </div>
                            <div>
                                <h5>Ime in priimek</h5>
                                <p>{{submission.mother_data.first_name}} {{submission.mother_data.last_name}}</p>
                            </div>
                            <div>
                                <h5>Naslov</h5>
                                <p>{{formatAddress(submission.mother_data.address)}}</p>
                            </div>
                            <div>
                                <h5>Telefonska številka</h5>
                                <p>{{submission.mother_data.phone_number}}</p>
                            </div>
                            <div>
                                <h5>Email</h5>
                                <p>{{submission.mother_data.email || "/"}}</p>
                            </div>

                        </div>
                        <div v-if="submission.father_data.parent_type === 5">
                            Ni podatka
                        </div>
                        <div class="pr-5" v-else >
                            <h4>{{getParentTypeText(submission.father_data.parent_type)}}</h4>
                            <div>
                                <h5>Status</h5>
                                <p> {{submission.father_data.parent_status === 1 ? 'Zaposlen' : submission.father_data.parent_status === 2 ? 'Nezaposlen' : 'Drugo'}}</p>
                            </div>
                            <div>
                                <h5>Ime in priimek</h5>
                                <p>{{submission.father_data.first_name}} {{submission.father_data.last_name}}</p>
                            </div>
                            <div>
                                <h5>Naslov</h5>
                                <p>{{formatAddress(submission.father_data.address)}}</p>
                            </div>
                            <div>
                                <h5>Telefonska številka</h5>
                                <p>{{submission.father_data.phone_number}}</p>
                            </div>
                            <div>
                                <h5>Email</h5>
                                <p>{{submission.father_data.email || "/"}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-md-flex justify-content-md-between">
                        <div>
                            <h5>Vedno dosegljiv telefon</h5>
                            <p>{{submission.reachable_phone_number}}</p>
                        </div>
                        <div>
                            <h5>Elektronski naslov otroka</h5>
                            <div>
                                <p>{{submission.reachable_child_email}}</p>
                                <div>
                                    <b-badge v-if="submission.reachable_child_email_allow_news_letters" variant="success">Dovoljeno Obveščanje</b-badge>
                                    <b-badge v-else variant="warning">Nedovoljeno Obveščanje</b-badge>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5>Elektronski naslov starša ali skrbnika</h5>
                            <div>
                                <p>{{submission.reachable_parent_email}}</p>
                                <div>
                                    <b-badge v-if="submission.reachable_parent_email_allow_news_letters" variant="success">Dovoljeno Obveščanje</b-badge>
                                    <b-badge v-else variant="warning">Nedovoljeno Obveščanje</b-badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <h3>PODATKI PLAČNIKA</h3>
                    <div class="d-sm-flex">
                        <div>
                            <h5>Ime in priimek</h5>
                            <p>{{submission.payee_first_name}} {{submission.payee_last_name}}</p>
                        </div>
                        <div class="ml-sm-3">
                            <h5>Naslov</h5>
                            <p>{{formatAddress(submission.payee_address)}}</p>
                        </div>
                    </div>
                    <div class="d-md-flex justify-content-md-between">
                        <div>
                            <h5>Elektronski naslov</h5>
                            <p>{{submission.payee_email}}</p>
                        </div>
                        <div>
                            <h5>Davčna številka</h5>
                            <p>{{submission.payee_tax_number}}</p>
                        </div>
                        <div>
                            <h5>Način plačila</h5>
                            <p>{{paymentType(submission.payment_type)}}</p>
                        </div>
                        <div>
                            <h5>Št. obrokov</h5>
                            <p>{{submission.number_of_installments}}</p>
                        </div>
                    </div>
                    <div v-if="submission.payment_type === 4" class="mt-1 d-md-flex justify-content-md-between">
                        <div>
                            <h5>Številka transakcijskega računa</h5>
                            <p>{{submission.direct_debit_trr}}</p>
                        </div>
                        <div>
                            <h5>Odprt pri</h5>
                            <p>{{submission.direct_debit_bank_name}}</p>
                        </div>
                        <div>
                            <h5>Datum odtegljaja</h5>
                            <p>{{submission.direct_debit_day}}</p>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <h3>OTROKOV OSEBNI ZDRAVNIK</h3>
                    <div class="d-sm-flex">
                        <div>
                            <h5>Ime in priimek zdravnika</h5>
                            <p v-if="submission.doctor">{{submission.doctor.first_name}} {{submission.doctor.last_name}}</p>
                            <p v-else>{{submission.doctor_first_name}} {{submission.doctor_last_name}}</p>
                        </div>
                        <div class="ml-sm-3">
                            <h5>Naziv zdravstvene ustanove/ambulante</h5>
                            <p v-if="submission.doctor">{{submission.doctor.title_1}} {{submission.doctor.title_2}}</p>
                            <p v-else>{{submission.doctor_institution}}</p>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <h3>POSEBNOSTI OTROKA</h3>
                    <div>
                        <h5>Posebnosti otroka</h5>
                        <p>{{submission.special_characteristics || "/" }}</p>
                    </div>
                    <div class="d-sm-flex">
                        <div>
                            <h5>Omejitve prehranjevanja/alergije</h5>
                            <p>{{dietaryType(submission.dietary_type)}}</p>
                        </div>
                        <div class="ml-sm-3" v-if="submission.dietary_type === 5">
                            <h5>Drugo</h5>
                            <p>{{submission.other_dietary_restriction || "/" }}</p>
                        </div>
                        <div class="ml-sm-3" v-else>
                            <h5>Druge omejitve prehranjevanja/alergije</h5>
                            <p>{{submission.dietary_restriction || "/" }}</p>
                        </div>


                    </div>
                    <div>
                        <h5>Psihosomatske posebnosti</h5>
                        <p>{{submission.disease || "/" }}</p>
                    </div>
                    <div>
                        <h5>Bolezenska stanja</h5>
                        <p>{{submission.disease_states || "/" }}</p>
                    </div>
                    <div>
                        <h5>Vzgojne in druge posebnosti otroka</h5>
                        <p>{{submission.peculiarities || "/" }}</p>
                    </div>
                    <div class="d-sm-flex">
                        <div>
                            <h5>Ali redno prejema zdravila</h5>
                            <p>{{submission.has_medications ? 'Da' : 'Ne'}}</p>
                        </div>
                        <div v-if="submission.has_medications" class="ml-sm-3">
                            <h5>Zdravila</h5>
                            <p>{{submission.medications || "/" }}</p>
                        </div>
                    </div>
                    <div class="d-sm-flex">
                        <div>
                            <h5>Odločba o usmerjenosti otroka s posebnimi potrebami</h5>
                            <p v-if="submission.special_needs_order">Da</p>
                            <p v-else>Ne</p>
                        </div>
                        <div class="ml-sm-3">
                            <h5>Vrsta primanjkljaja, ovire oziroma motnje</h5>
                            <p>{{submission.special_needs_description || "/" }}</p>
                        </div>
                    </div>
                    <div class="d-sm-flex">
                        <div>
                            <h5>Otrok zna plavati</h5>
                            <p>{{swimmingSkillsLevel(submission.swimming_skills_level)}}</p>
                        </div>
                        <div class="ml-sm-3">
                            <h5>Odločba o prepovedi stikov</h5>
                            <p>{{restrainingOrderType(submission.restraining_order_type)}}</p>
                        </div>
                        <div class="ml-sm-3" v-if="submission.restraining_order_attachment_name">
                            <h5>Odločba o prepovedi stikov</h5>
                            <b-button @click="downloadPdf(submission.restraining_order_attachment_name)" size="sm" variant="primary">Prenesi dokumente</b-button>
                        </div>
                    </div>
                    <div class="d-sm-flex">
                        <div>
                            <h5>Ali otrok biva v vzgojnem ali drugem zavodu, mladinskem domu ipd.</h5>
                            <p v-if="submission.child_lives_in_institution">Da</p>
                            <p v-else>Ne</p>
                        </div>
                        <div v-if="submission.child_lives_in_institution">
                            <h5>Kontaktna oseba</h5>
                            <p>{{submission.child_care_contact_info}}</p>
                        </div>
                        <div v-if="submission.child_lives_in_institution">
                            <h5>Naziv</h5>
                            <p>{{submission.child_care_institution}}</p>
                        </div>
                    </div>
                    <div class="d-sm-flex">
                        <div>
                            <h5>Ali nam želite še kaj posebej sporočiti?</h5>
                            <p>{{submission.additional_message || "/"}}</p>
                        </div>
                    </div>

                </div>
                <div class="mt-2">
                    <h4>S podpisom te PRIJAVE in IZJAVE:</h4>
                    <p><strong>a.</strong> izjavljam, da za potrebe prijave na letovanje in nadaljevalnih postopkov (priprava pogodbe, priprava in izvedba letovanja), dovoljujem Zvezi prijateljev mladine (ZPM) Maribor obdelavo vseh posredovanih osebnih podatkov skladno z zakonodajo. Razumem, da ZPM Maribor jamči za varstvo posredovanih podatkov v skladu z veljavno zakonodajo o varovanju osebnih podatkov.
                    </p>
                    <p><strong>b.</strong> se obvezujem, da bom stroške letovanja poravnal na dogovorjen način in v dogovorjenem roku kot bo navedeno na obvestilu, ki ga bom po e-pošti na moj navedeni naslov prejel skupaj z izračunom za plačilo.
                    </p>
                    <p><strong>c.</strong> se strinjam, da je lahko otrok, v kolikor na letovanju zboli ali se poškoduje, zdravljen po principih uradne medicine.<br> V kolikor otrok ni cepljen po programu cepljenja otrok v Republiki Sloveniji, se strinjam, da bom v primeru otrokove poškodbe ali bolezni, na poziv, ki ga bom v takem primeru dobil s strani zdravstvenega oziroma pedagoškega spremstva otrok na letovanjih, osebno (v lastni režiji) nemudoma prišel po otroka in ga odpeljal z letovanja domov. To izjavo podpisujem na zahtevo organizatorja letovanja ZPM Maribor in se zavedam, da lahko organizator zaradi moje morebitne odklonitve podpisa iste izjave odkloni sprejem otroka na letovanje.</p>
                    <p><strong>d.</strong> dovoljujem, da ZPM Maribor pridobi vse potrebne zdravstvene podatke o otroku iz otrokove zdravstvene kartoteke za potrebe zdravstva na letovanju; vključno z morebitnim posebnim mnenjem zdravnika, da je otrok primeren za vključitev na letovanje. Vpogled v podatke o zdravstvenem stanju je dovoljen strokovni službi ZPM Maribor in spremljajočemu zdravstvenemu osebju na letovanju.
                    </p>
                    <p><strong>e.</strong>dovoljujem mojemu otroku potovanje v tujino in s tem prehod državne meje v organizaciji ZPM Maribor in sem seznanjen, da za prehod državne meje potrebuje otrok veljaven potni list ali osebno izkaznico.  </p>
                    <p><strong>f.</strong> izjavljam, da sem seznanjen z dejstvom, da bo moj otrok letoval v spremstvu prostovoljnih sodelavcev ZPM Maribor in z lastnim podpisom sprejemam splošne pogoje poslovanja ter hišni red za otroke na letovanju, dovoljujem fotografiranje in snemanje otroka, uporabo teh materialov za potrebe ZPM Maribor in tudi za javno objavo. </p>
                    <p><strong>g.</strong> izjavljam, da sem seznanjen s pogoji poslovanja in hišnim redom za otroke na letovanju (v tiskani obliki kot priloga pogodbe, javna objava na sedežu ZPM Maribor in na spletni strani ZPM Maribor) in se zavezujem, da bom nemudoma prišel po otroka, če mu bo zaradi zdravstvenih ali epidemioloških razlogov oziroma kršenja hišnega reda in neprimernega vedenja na letovanju izrečen ZDRAVSTVENI ali VZGOJNI UKREP NAPOTITVE DOMOV.</p>
                    <p> Prijavitelj s svojim podpisom zagotavljam resničnost navedenih podatkov in zanje v celoti odgovarjam. Strinjam se, da lahko ZPM Maribor te podatke uporablja v skladu s svojimi pravilniki. Kot moj podpis velja tudi poslana prijava preko spletne aplikacije ZPM Maribor za prijavo na letovanje.</p>
                    <p><i>ZPM Maribor jamči za varstvo posredovanih podatkov v skladu z veljavno zakonodajo o varovanju osebnih podatkov. </i></p>
                    <div class="d-md-flex">
                        <div>
                            <h5>Datum prijave</h5>
                            <p>{{$dayjs(submission.date_created).utc().format('DD.MM.YYYY HH:mm:ss')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <b-button @click="downloadPdf(submission.submission_pdf_name)" variant="primary">Pridobi PDF dokument</b-button>
            </div>
        </b-card>
    </div>
</template>

<script>
    import {BCard, BButton, BBadge, BFormCheckbox, BRow, BCol} from 'bootstrap-vue'
    import VueSelect from 'vue-select'

    export default {
        components: {
            BCol,
            VueSelect,
            BCard,
            BButton,
            BBadge,
            BFormCheckbox,
            BRow
        },
        data() {
            return {
                submission: null,
                doctors:null,
                edit:false,
                newDoctor:null,
                parentTypes: [
                    {text: 'Mati', value: 1},
                    {text: 'Oče', value: 2},
                    {text: 'Skrbnik / Skrbnica', value: 3},
                    {text: 'Rejnik / Rejnica', value: 4},
                    {text: 'Ni podatka', value: 5}
                ]
            }
        },
        methods: {
            async updateDoctor() {
                try {
                    this.$store.commit('app/START_LOADING')
                    await this.$http.post(`/api/private/v1/submission/${this.submission.id}/${this.newDoctor}`)
                    this.$printSuccess('Zdravnik je bil uspešno posodobljen')
                    this.edit = false
                    this.newDoctor = null
                    await this.loadData()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri posodabljanju zdravnika')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            formatAddress(address) {
                const street = `${address.street} ${address.street_number}`
                const zip = `${address.post_code ? `${address.post_code.zip} ${address.post_code.name}` : `${address.zip} ${address.city}`}`
                const country = `${address.country_data ? `${address.country_data.descriptor}` : `${address.country}`}`
                return `${street}, ${zip}, ${country}`
            },
            async loadDoctors() {
                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.get('/api/private/v1/doctor/')
                    this.doctors = response.data ?? []
                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            async loadData() {
                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.get(`/api/private/v1/submission/${this.$route.params.id}`)
                    this.submission = response.data ?? []
                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            async submissionDeny(data) {
                try {
                    const confirmDelete = await this.$confirmDialog(`Ali res želite zavrniti vlogo: ${data.first_name} ${data.last_name}`)
                    if (!confirmDelete) return
                    this.$store.commit('app/START_LOADING')
                    await this.$http.put(`/api/private/v1/submission/${data.id}/deny`)
                    this.$printSuccess('Vloga je bila zavrnjena')
                    await this.loadData()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri zavračanju vloge')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            gender(gender) {
                return gender === 1 ? 'M' : gender === 2 ? 'Ž' : ''
            },
            submissionType(type) {
                if (type === 1) return 'Samoplačniško'
                else if (type === 2) return 'Zdravstveno'
                else if (type === 3) return 'Subvencionirano s strani občine'
                else if (type === 4) return 'Subvencionirano s strani občine in zdravstva'
                return '/'
            },
            paymentType(type) {
                if (type === 1) return 'Gotovina'
                else if (type === 2) return 'Plačilna kartica'
                else if (type === 3) return 'Nakazilo na TRR po predračunu'
                else if (type === 4) return 'SEPA'
                return '/'
            },
            dietaryType(type) {
                if (type === 1) return 'Brez posebnosti'
                else if (type === 2) return 'Vegetarijanska'
                else if (type === 3) return 'Celiakija'
                else if (type === 4) return 'Brez laktoze'
                else if (type === 5) return 'Drugo'
                return '/'
            },
            swimmingSkillsLevel(type) {
                if (type === 1) return 'Dobro'
                else if (type === 2) return 'Slabo'
                else if (type === 3) return 'Ne zna'
                return '/'
            },
            restrainingOrderType(type) {
                if (type === 1) return 'Ne'
                else if (type === 2) return 'Z Materjo'
                else if (type === 3) return 'Z Očetom'
                else if (type === 4) return 'Z obema staršema'
                return '/'
            },
            schoolType(type) {
                if (type === 1) return 'Predšolsko'
                else if (type === 2) return 'Osnovna šola'
                else if (type === 3) return 'Srednja šola'
            },
            async downloadPdf(fileName) {
                try {
                    this.$store.commit('app/START_LOADING')
                    window.open(`/api/public/v1/download_file/${fileName}`)
                } catch (error) {
                    this.$printError('Prišlo je do napake pri prenosu pdf')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            getParentTypeText(value) {
                return this.parentTypes.find(f => f.value === value).text
            }
        },
        async mounted() {
            await this.loadData()
            await this.loadDoctors()
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
